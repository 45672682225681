/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

import { KaChingInitData, SlotOptions } from '@hubcms/domain-ads';

import { KaChing } from '../../../domain/kaChing';

export type RegisterAdFn = (adFormat: string, adSlot: string, options?: SlotOptions) => void;
export type UnRegisterAdFn = (adFormat: string, adSlot: string) => void;
export type adFormatCounter = Record<string, string[]>;

export const KaChingContext = createContext<{
  registerAd: RegisterAdFn;
  unregisterAd: UnRegisterAdFn;
  kachingInstance: KaChing | null;
  kachingInitData: Partial<KaChingInitData>;
  adFormatCounter: adFormatCounter;
  isPreview: boolean;
  isFinished: boolean;
}>({
  registerAd: () => {},
  unregisterAd: () => {},
  kachingInstance: null,
  kachingInitData: {},
  adFormatCounter: {},
  isPreview: false,
  isFinished: false,
});
